import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

Vue.config.productionTip = false

// 导入全局样式
import './assets/css/global.css'

// 导入字体和图标
// import './assets/fonts/iconfont.css'

// 引入axios，前端后端连通,设置默认基础路径
import axios from "axios";

// axios.defaults.baseURL = 'http://127.0.0.1:8088/';  // 本地测试
axios.defaults.baseURL = 'https://ssp-manage.upower.com'; // 由lb-80代理到后端的8088
// 拦截所有请求
Vue.prototype.$http = axios  // 让axios作为实例来访问


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
