import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [

    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        component: () => import('../components/Login')
    },
    {
        path: '/commit',
        component: () => import('../components/Commit')
    },
    {
        path: '/scmreset',
        component: () => import('../components/ScmReset')
    },
];

// mode='history' 去掉url中的#
const router = new VueRouter({
    mode: 'history',
    routes
});

export default router
